import { useState } from "react";
import { useTranslation } from "react-i18next";

import { HTTPResponseError, UnknownError } from "services/errors";
import { useUser } from "components/helper/userContext";
import { useSession } from "components/helper/sessionContext";

import {
    getCurrentUser,
    getSessionInformation,
    requestSmsVerificationCode,
} from "components/helper/callApi";

import LoginPassword from "./LoginPassword";
import SMSVerificationForm from "./common/SMSVerificationForm";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { useNavigate } from "react-router-dom";
import { SessionType } from "types/sessionType";
import { getAdditionalFields } from "services/additionalFields";

type Props = {
    codeOnline: string;
    isSignatureRequired: boolean;
};

const LoginEmailPasswordSMS = ({ codeOnline, isSignatureRequired }: Props) => {
    const { i18n } = useTranslation();
    const userContext = useUser();
    const { setSession } = useSession();
    const navigate = useNavigate();

    const [template, setTemplate] = useState("default");
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [successParams, setSuccessParams] = useState<{
        email?: string;
        password?: string;
    }>({});

    const requestVerificationCode = async (email: string, password: string) => {
        try {
            const res = await requestSmsVerificationCode({
                email,
                password,
                codeOnline,
            });

            if (res.status !== 200) {
                throw new HTTPResponseError(res);
            }

            return res.json().then((json) => setPhoneNumber(json.phoneNumber));
        } catch (error) {
            if (!(error instanceof HTTPResponseError)) {
                throw i18n.t("errorUnknown");
            }

            const res = (error as HTTPResponseError).response;

            switch (res.status) {
                case 401:
                    throw i18n.t("errorCredentialsIncorrect");
                case 409:
                    return res
                        .json()
                        .catch(() => {
                            throw i18n.t("errorUnknown");
                        })
                        .then((json) => {
                            if (
                                "ONLINE_SCENARIO_NOT_COMPATIBLE" ===
                                json.errorCode
                            ) {
                                throw i18n.t(
                                    "passwordEmailErrorScenarioIncorrect"
                                );
                            }

                            if (
                                "ONLINE_PHONE_NUMBER_INVALID" === json.errorCode
                            ) {
                                throw i18n.t("errorPhoneNumberInvalid");
                            }

                            throw i18n.t("errorUnknown");
                        });
                default:
                    throw i18n.t("errorUnknown");
            }
        }
    };

    const loginUserOnSession = async (loginResponse: Response) => {
        const response = await loginResponse.json();

        if (response.token) {
            localStorage.setItem(LS_AUTH_TOKEN_KEY, response.token);
        }

        let responseCurrentUser;
        try {
            responseCurrentUser = await getCurrentUser(
                getAdditionalFields({
                    isSignatureRequired,
                })
            );
        } catch (error) {
            throw new UnknownError();
        }

        if (!responseCurrentUser.ok) {
            throw new HTTPResponseError(responseCurrentUser);
        }

        const userData = await responseCurrentUser.json();
        userContext.setUser(userData);

        // Get session information
        let sessionResponse;

        try {
            sessionResponse = await getSessionInformation(codeOnline, [
                "zoomSignature",
            ]);
            if (sessionResponse.status !== 200) {
                throw new HTTPResponseError(sessionResponse);
            }
        } catch (error) {
            if (!(error instanceof HTTPResponseError)) {
                throw i18n.t("errorUnknown");
            }
            switch (error.response.status) {
                case 401:
                    throw i18n.t("errorYouMustBeLog");
                case 403:
                    throw i18n.t("error403");
                default:
                    throw i18n.t("errorUnknown");
            }
        }

        const sessionData: SessionType = await sessionResponse.json();
        setSession(sessionData);

        navigate(`/session/${codeOnline}`, { replace: true });
    };

    if ("default" === template) {
        return (
            <LoginPassword
                handleSubmit={(email: string, password: string) =>
                    requestVerificationCode(email, password).then(() => {
                        setTemplate("verification-code-form");
                        setSuccessParams({ email, password });
                    })
                }
                codeOnline={codeOnline}
                isSignatureRequired={isSignatureRequired}
            />
        );
    }

    return (
        <SMSVerificationForm
            phoneNumber={phoneNumber as string}
            codeOnline={codeOnline}
            email={successParams.email as string}
            password={successParams.password as string}
            requestVerificationCode={requestVerificationCode}
            onSubmitSuccess={loginUserOnSession}
        />
    );
};

export default LoginEmailPasswordSMS;

import { ADDITIONAL_FIELDS_API } from "components/helper/callApi";
import { Scenario } from "enums/scenario";

type Params = {
    isSignatureRequired?: boolean;
    shouldCountOnlineDevice?: boolean;
    fields?: ADDITIONAL_FIELDS_API[];
};

export const shouldCountOnlineDevice = (scenario: Scenario) =>
    [
        Scenario.EMAIL_PASSWORD,
        Scenario.EMAIL_PASSWORD_SMS,
        Scenario.PIN,
    ].includes(scenario);

export const getAdditionalFields = (params: Params) => {
    const { isSignatureRequired, shouldCountOnlineDevice, fields } = params;

    const additionalFields = new Set<ADDITIONAL_FIELDS_API>(); //Using set in order to prevent doubles

    if (isSignatureRequired) {
        additionalFields.add(ADDITIONAL_FIELDS_API.PROXIES);
        additionalFields.add(ADDITIONAL_FIELDS_API.IS_SIGNED);
    }

    if (shouldCountOnlineDevice) {
        additionalFields.add(ADDITIONAL_FIELDS_API.LIVE_DEVICE_COUNT);
    }

    if (fields?.length) {
        fields.forEach((field) => additionalFields.add(field));
    }

    return Array.from(additionalFields);
};

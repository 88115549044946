export enum Scenario {
    PIN = "reference_multi_devices",
    PSEUDO = "pseudo",
    NOM_PRENOM = "nom_prenom",
    NOM_PRENOM_EMAIL = "nom_prenom_email",
    PSEUDO_EMAIL = "pseudo_email",
    EQUIPE = "equipe",
    EMAIL_PASSWORD = "email_password",
    EMAIL_PASSWORD_SMS = "email_password_sms",
}

export enum EVENT {
    CONNECT = "connect",
    CONNECT_ERROR = "connect_error",
    DISCONNECT = "disconnect",
    OPEN_VOTE = "openVote",
    CLOSE_VOTE = "closeVote",
    VOTE_REQUEST = "voteRequest",
    DEVICE_COUNT_CHANGED = "deviceCountChanged",
    ANSWER = "answer",
    RETURN_HOME = "returnToHome",
    RELOAD_REQUEST = "reloadRequest",
    KILL_PARTICIPANTS = "killParticipants",
    SESSION_CHANGE_STATUS = "changeSessionStatus",
}

import { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { QuestionType } from "types/dataVoteType";
import useVoteState, { VoteState, Person, VoteStatus } from "./useVoteState";
import { useHolderVoteMustBeAppliedToProxies } from "../common/AutomaticVoteForProxiesComponents/useHolderVoteMustBeAppliedToProxies";

import VoteHeader from "../common/Header/VoteHeader";
import VoteBasic from "../common/VoteBasic";
import VoteDistribution from "../common/VoteDistribution";
import ActionSection from "../common/ActionSection";
import getGlobalStatus from "./services/getGlobalStatus";
import ToggleSwitch from "../common/AutomaticVoteForProxiesComponents/ToggleSwitch";
import ProxiesAccordion from "../common/AutomaticVoteForProxiesComponents/ProxiesAccordion";
import ParticipantHeader from "../common/ParticipantHeader";
import emitAsync from "components/helper/emitAsync";
import NoVoteAllowedMessage from "../common/NoVoteAllowedMessage";

type Props = {
    question: QuestionType;
    user: Person;
    proxies: Array<Person>;
    responseFormatVersion: number;
    publicKey: string;
};

const isZoomTemplate = document
    .getElementById("DesignVote")
    ?.classList.contains("streaming");

const Deliberation = ({
    question,
    user,
    proxies,
    responseFormatVersion,
    publicKey,
}: Props) => {
    const [votes, dispatchVoteAction] = useVoteState(question, proxies, user);
    const {
        holderVoteMustBeAppliedToProxies,
        setHolderVoteMustBeAppliedToProxies,
    } = useHolderVoteMustBeAppliedToProxies(
        question.authorizeWeightDistribution
    );

    useEffect(() => {
        const userAnswer = votes[0].answers.find((answer) => answer.weight > 0);

        if (holderVoteMustBeAppliedToProxies && userAnswer) {
            dispatchVoteAction({
                type: "replaceVoteForEveryParticipant",
                id: userAnswer.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchVoteAction, holderVoteMustBeAppliedToProxies]);

    const { i18n } = useTranslation();

    const participants = [user, ...proxies];

    const handleValidate = (voterId: string) => {
        const voteToSend = votes.find(
            (vote) => vote.voterId === voterId
        ) as VoteState;

        const answersWithWeight = voteToSend.answers.filter(
            (answer) => answer.weight > 0
        );

        const data = {
            votingDeviceId: voterId,
            responseFormatVersion: responseFormatVersion,
            publicKey: publicKey,
            answers: answersWithWeight.map((answer) => ({
                questionId: question.id,
                answer: answer.id,
                weight: answer.weight,
            })),
        };

        dispatchVoteAction({
            type: "sendAnswersRequest",
            voterId: voterId,
        });

        emitAsync(data)
            .then(() => {
                dispatchVoteAction({
                    type: "sendAnswersSuccess",
                    voterId: voterId,
                });
            })
            .catch(() => {
                dispatchVoteAction({
                    type: "sendAnswersError",
                    voterId: voterId,
                });
            });
    };

    const handleValidateProxies = () => {
        votes.forEach((vote) => {
            if (vote.voteStatus === VoteStatus.PendingValidation) {
                handleValidate(vote.voterId);
            }
        });
    };

    const renderVoteBasic = (voter: Person, state: VoteState) => {
        return (
            <VoteBasic
                propositions={question.propositions}
                answers={state.answers}
                isSecret={question.isSecret}
                voteStatus={state.voteStatus}
                replaceProposal={(propositionId: string) => {
                    //Change all answers in the case of automatic vote for proxies toggle's is active with user's answer.
                    if (
                        voter.id === user.id &&
                        holderVoteMustBeAppliedToProxies
                    ) {
                        dispatchVoteAction({
                            type: "replaceVoteForEveryParticipant",
                            id: propositionId,
                        });
                    } else {
                        dispatchVoteAction({
                            type: "updateWeightVote",
                            id: propositionId,
                            voterId: voter.id,
                            weight: voter.weight,
                        });

                        setHolderVoteMustBeAppliedToProxies(false);
                    }
                }}
                proposalsOrientation={
                    proxies.length > 0 ? "horizontal" : "vertical"
                }
                voterName={voter.displayName}
            />
        );
    };

    const renderVoteDistribution = (voter: Person, state: VoteState) => {
        return (
            <VoteDistribution
                propositions={question.propositions}
                answers={state.answers}
                isSecret={question.isSecret}
                voterWeight={voter.weight}
                voteStatus={state.voteStatus}
                updateWeight={(id: string, weight: number) => {
                    dispatchVoteAction({
                        id,
                        weight,
                        type: "updateWeightVote",
                        voterId: voter.id,
                    });
                }}
                proposalsOrientation={
                    proxies.length > 0 ? "horizontal" : "vertical"
                }
            />
        );
    };

    const renderVoteDistributionOrBasic = (
        participant: Person,
        participantVote: VoteState
    ) => {
        if (0 === participant.weight) {
            return <NoVoteAllowedMessage displayName={user.displayName} />;
        }

        if (question.authorizeWeightDistribution && 1 !== participant.weight) {
            // No proxies + weight distribution
            return renderVoteDistribution(participant, participantVote);
        }

        // No proxies + no weight distribution or plurinominal
        return renderVoteBasic(participant, participantVote);
    };

    const voteStatusArray = votes.map((vote) => vote.voteStatus);

    const renderSucceededMessageSend = (item: number) => {
        return voteStatusArray[item] === VoteStatus.Success ? (
            <StyledSuccessVotedMessage dir={i18n.dir(i18n.language)}>
                {i18n.t(`sentSucceded.${process.env.REACT_APP_THEME}`)}
            </StyledSuccessVotedMessage>
        ) : null;
    };

    const renderWithAutomaticVoteForProxies = () => {
        return (
            <>
                {/* Holder voter part */}
                <>
                    <StyledNumberOfVoices dir={i18n.dir(i18n.language)}>
                        {i18n.t("numberOfVote")} {user.weight}
                    </StyledNumberOfVoices>
                    {renderSucceededMessageSend(0)}
                    {renderVoteBasic(user, votes[0])}
                    <ToggleSwitch
                        checked={holderVoteMustBeAppliedToProxies}
                        onChange={setHolderVoteMustBeAppliedToProxies}
                    />
                </>
                {/* Proxies voters part inside the accordion (children props) */}
                <ProxiesAccordion>
                    <>
                        {proxies.map((proxy: Person, item: number) => {
                            const proxyVote = votes.find(
                                (vote) => vote.voterId === proxy.id
                            ) as VoteState;

                            return (
                                <div key={proxy.id}>
                                    <NameWrapper>
                                        <StyledName
                                            dir={i18n.dir(i18n.language)}
                                        >
                                            {i18n.t("proxy")}:
                                        </StyledName>
                                        <StyledName>
                                            {proxy.displayName}
                                        </StyledName>
                                    </NameWrapper>

                                    <>
                                        {proxy.weight > 1 ? (
                                            <StyledNumberOfVoices
                                                dir={i18n.dir(i18n.language)}
                                            >
                                                {i18n.t("numberOfVote")}
                                                {proxy.weight}
                                            </StyledNumberOfVoices>
                                        ) : null}

                                        {renderSucceededMessageSend(item + 1)}

                                        {renderVoteBasic(proxy, proxyVote)}
                                    </>
                                </div>
                            );
                        })}
                    </>
                </ProxiesAccordion>
            </>
        );
    };

    const renderVote = () => {
        const userHasProxies = proxies.length > 0;

        if (userHasProxies && !question.authorizeWeightDistribution) {
            return renderWithAutomaticVoteForProxies();
        }

        return participants.map((participant, item) => {
            const participantVote = votes.find(
                (vote) => vote.voterId === participant.id
            ) as VoteState;

            return (
                <div key={participant.id}>
                    <ParticipantHeader
                        participantIndex={item}
                        participant={participant}
                        participantVote={participantVote}
                        voteIsWithDistribution={
                            question.authorizeWeightDistribution
                        }
                    />
                    {renderVoteDistributionOrBasic(
                        participant,
                        participantVote
                    )}
                </div>
            );
        });
    };

    const renderActionSection = () => {
        const userVote = votes.find(
            (vote) => vote.voterId === user.id
        ) as VoteState;

        return (
            <ActionSection
                voteStatus={
                    participants.length > 1
                        ? getGlobalStatus(voteStatusArray)
                        : userVote.voteStatus
                }
                validateVote={
                    participants.length > 1
                        ? handleValidateProxies
                        : () => {
                              handleValidate(user.id);
                          }
                }
                resetVote={() => {
                    dispatchVoteAction({
                        type: "resetVote",
                    });
                }}
            />
        );
    };

    return (
        <Wrapper>
            <WrapperVote>
                <VoteHeader question={question} />
                {renderVote()}
                {getGlobalStatus(voteStatusArray) === VoteStatus.NotAllowed
                    ? null
                    : renderActionSection()}
            </WrapperVote>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const WrapperVote = styled.div`
    width: ${() => (isZoomTemplate ? "90%" : "100%")};
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

const NameWrapper = styled.div`
    display: flex;
    font-size: 15px;
`;

const StyledName = styled.p`
    margin-bottom: 0;
    font-weight: 600;
    margin-right: 5px;
    }
`;

const StyledSuccessVotedMessage = styled.p`
    color: #60b860;
    margin: 0;
    font-size: 0.85rem;
`;

const StyledNumberOfVoices = styled.div`
    color: #626262;
    padding-bottom: 0.5rem;
    font-size: 14px;
`;

export default Deliberation;

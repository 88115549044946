import { getSocket } from "components/socket";
import { EVENT } from "types/socketEventsType";
import encryptAnswers from "components/helper/encryptAnswers";

const emitAsync = async (args: {
    votingDeviceId: string;
    responseFormatVersion: number;
    publicKey: string;
    answers: { questionId: number; answer: string; weight: number }[];
}) => {
    let answers: any = args.answers;
    const shouldEncryptAnswer =
        args.publicKey !== null &&
        args.publicKey !== undefined &&
        args.publicKey !== "";

    if (shouldEncryptAnswer) {
        answers = await encryptAnswers(args.publicKey, args.answers);
    }

    return new Promise<{ [key: string]: any }>((resolve, reject) => {
        getSocket().emit(
            EVENT.ANSWER,
            {
                votingDeviceId: args.votingDeviceId,
                responseFormatVersion: args.responseFormatVersion,
                answers: answers,
            },
            (res: any) => {
                if ("SUCCESS" === res.status) {
                    resolve(res.data);
                } else {
                    reject({ code: res.code, message: res.message });
                }
            }
        );
    });
};

export default emitAsync;

import nacl from "tweetnacl";
import util from "tweetnacl-util";

const encryptAnswers = async (publicKey: string, answers: any) => {
    const ephemeralKeyPair = nacl.box.keyPair();
    const pubKeyUInt8Array = util.decodeBase64(publicKey);
    const msgParamsUInt8Array = util.decodeUTF8(JSON.stringify(answers));
    const nonce = nacl.randomBytes(nacl.box.nonceLength);
    const encryptedMessage = nacl.box(
        msgParamsUInt8Array,
        nonce,
        pubKeyUInt8Array,
        ephemeralKeyPair.secretKey
    );

    return {
        message: util.encodeBase64(encryptedMessage),
        publicKey: util.encodeBase64(ephemeralKeyPair.publicKey),
        nonce: util.encodeBase64(nonce),
    };
};

export default encryptAnswers;

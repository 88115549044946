import { EnumDescriptor } from "types/enumDescriptor";

export enum Language {
    FR = "fr",
    EN = "en",
    DE = "de",
    ES = "es",
    AR = "ar",
}

export const LanguageMap = {
    fr: "fr-FR",
    en: "en-US",
    de: "de-DE",
    es: "es-ES",
    ar: "ar-SA",
};

export const LANGUAGES: Array<EnumDescriptor> = [
    { key: Language.FR, description: "Français" },
    { key: Language.DE, description: "Deutsch" },
    { key: Language.EN, description: "English" },
    { key: Language.ES, description: "Spanish" },
    { key: Language.AR, description: "عربي" },
];

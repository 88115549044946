import styled from "styled-components";

import { User } from "types/User";

import LogoutButton from "./LogoutButton";
import LogoutWrapper from "components/common/LogoutWrapper";

interface StyledDivProps {
    isSessionActive: boolean;
}

type Props = {
    isSessionActive: boolean;
    codeOnline: string;
    user: User;
};

const WrapperSessionInformation = ({
    codeOnline,
    user,
    isSessionActive,
}: Props) => {
    return (
        <>
            <StyledWrapper>
                <div>
                    <SquareWrapper isSessionActive={isSessionActive} />
                    <SessionNumber>{codeOnline}</SessionNumber>
                </div>
                <LogoutWrapper>
                    <LogoutButton />
                </LogoutWrapper>
            </StyledWrapper>
        </>
    );
};

const SquareWrapper = styled.div<StyledDivProps>`
    margin: 1px;
    width: 1.25rem;
    background-color: ${(props) =>
        props.isSessionActive ? " #60b860" : "#607D91"};
`;

const SessionNumber = styled.div`
    padding: 0.25rem;
    font-size: 0.75rem;
`;

const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.25rem;
    > div {
        border: 1px solid #ccc;
        margin-right: 1rem;
        display: flex;
        height: 100%;
    }
    @media (min-width: 768px) {
        padding-bottom: 0;
    }
`;

export default WrapperSessionInformation;

import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Language, LANGUAGES } from "../../Language";
import { SESSION_LANG_STORAGE_KEY } from "../../constants/constants";

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const defaultLanguage: string = Language.EN;

    useEffect(() => {
        if (changeLanguageIfValid(navigator.language.split("-")[0])) {
            return;
        }

        if (
            changeLanguageIfValid(
                localStorage.getItem(SESSION_LANG_STORAGE_KEY)
            )
        ) {
            return;
        }

        i18n.changeLanguage(
            Language[defaultLanguage.toUpperCase() as keyof typeof Language]
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeLanguageIfValid = (
        lang: string | null | undefined
    ): boolean => {
        if (lang && Language[lang.toUpperCase() as keyof typeof Language]) {
            i18n.changeLanguage(
                Language[lang.toUpperCase() as keyof typeof Language]
            );
            document.documentElement.setAttribute("lang", lang.toLowerCase());
            return true;
        }
        return false;
    };

    let changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
        changeLanguageIfValid(event.target.value.toLocaleUpperCase());
    };

    return (
        <div>
            <div>
                <select
                    name="language"
                    value={i18n.language}
                    onChange={changeLanguage}
                    aria-label="Language"
                >
                    {LANGUAGES.map((lang, index) => (
                        <option key={index} value={lang.key}>
                            {lang.description}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default LanguageSelector;
